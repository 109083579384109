.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.font-quicksand {
  font-family: "Quicksand", sans-serif;
}

.font-pacifico {
  font-family: "Pacifico", sans-serif;
}

.font-oxygen {
  font-family: "Oxygen", sans-serif;
}

.font-raleway {
  font-family: "Raleway", sans-serif;
}

.depth {
  -webkit-box-shadow: 0px 16px 24px 2px rgba(2, 2, 2, 0.15),
    0px 6px 30px 5px rgba(0, 0, 0, 0.15), 0px 8px 10px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 16px 24px 2px rgba(2, 2, 2, 0.15),
    0px 6px 30px 5px rgba(0, 0, 0, 0.15), 0px 8px 10px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 16px 24px 2px rgba(2, 2, 2, 0.15),
    0px 6px 30px 5px rgba(0, 0, 0, 0.15), 0px 8px 10px -5px rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(51, 51, 51, .75);
  background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8) 3px,
      transparent 0px,
      transparent 6px
    ),
    repeating-linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5) 3px,
      transparent 0px,
      transparent 6px
    );
  box-shadow: 0px 0px 0px 3px #138808, 0px 0px 0px 6px #ffffff,
    0px 0px 0px 9px #ff9933;
}

.mybtn {
  transition: all 0.5s !important;
  background-color: white !important;
  color: #181818 !important;
  border-radius: 25px !important;
  border: none !important;
}

.mybtn:hover {
  transform: scale(1.15) !important;
  text-decoration: underline !important;
}

.mybtn-dark {
  background-color: rgb(18, 18, 18) !important;
  color: white !important;
  border: 0.5px solid rgba(120, 120, 120, 0.49) !important;
}

.depth-light {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgba(120, 120, 120, 0.125) !important;
}

.text-underline {
  text-decoration: underline dashed rgba(180, 180, 180, 0.75) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #000;
  margin: -3px 0 0 -3px;
}

.theme-light {
    background-color: rgb(18, 18, 18) !important;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  outline: none !important;
}

svg.tea {
  --secondary: #33406f;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

svg.tea #teabag {
  transform-origin: top center;
  transform: rotate(3deg);
  animation: swing 2s infinite;
}

svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13;
  animation: steamLarge 2s infinite;
}

svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9;
  animation: steamSmall 2s infinite;
}

@-moz-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-webkit-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-o-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-moz-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-webkit-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-o-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-moz-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-webkit-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-o-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}